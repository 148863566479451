import React from 'react';

function Community({width, height}) {
    return (
        <svg width={width ?width :"23px"} height={height ?height :"23px"} viewBox="0 0 35 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.29166 12.5117C8.45198 12.5117 9.56478 12.0293 10.3852 11.1707C11.2057 10.312 11.6667 9.14748 11.6667 7.93319C11.6667 6.7189 11.2057 5.55435 10.3852 4.69571C9.56478 3.83708 8.45198 3.35471 7.29166 3.35471C6.13133 3.35471 5.01854 3.83708 4.19806 4.69571C3.37759 5.55435 2.91666 6.7189 2.91666 7.93319C2.91666 9.14748 3.37759 10.312 4.19806 11.1707C5.01854 12.0293 6.13133 12.5117 7.29166 12.5117ZM27.7083 23.1948C28.8686 23.1948 29.9814 22.7124 30.8019 21.8538C31.6224 20.9952 32.0833 19.8306 32.0833 18.6163C32.0833 17.402 31.6224 16.2375 30.8019 15.3789C29.9814 14.5202 28.8686 14.0378 27.7083 14.0378C26.548 14.0378 25.4352 14.5202 24.6147 15.3789C23.7943 16.2375 23.3333 17.402 23.3333 18.6163C23.3333 19.8306 23.7943 20.9952 24.6147 21.8538C25.4352 22.7124 26.548 23.1948 27.7083 23.1948ZM7.29166 33.878C8.45198 33.878 9.56478 33.3956 10.3852 32.537C11.2057 31.6783 11.6667 30.5138 11.6667 29.2995C11.6667 28.0852 11.2057 26.9206 10.3852 26.062C9.56478 25.2034 8.45198 24.721 7.29166 24.721C6.13133 24.721 5.01854 25.2034 4.19806 26.062C3.37759 26.9206 2.91666 28.0852 2.91666 29.2995C2.91666 30.5138 3.37759 31.6783 4.19806 32.537C5.01854 33.3956 6.13133 33.878 7.29166 33.878Z"
                stroke="#3A325B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.3333 18.6163H13.125C9.91666 18.6163 7.29166 17.0901 7.29166 12.5117V24.721" stroke="#3A325B"
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Community;