import React from 'react';

function Error404() {
    return (
        <div>
            <p>
                Error 404: Page not found
            </p>
        </div>
    );
}

export default Error404;