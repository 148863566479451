import {useEffect, useState} from "react";
import OpacityMessage from "../UI/message/OpacityMessage";

function OpacityErrorSuccessDiv({error, setError, type}) {

    const [showElement, setShowElement] = useState(false)

    useEffect(() => {
        if (error)
            setShowElement(true)
    }, [error])

    return (
        <OpacityMessage
            type={type ? type : "error"}
            text={error}
            setError={setError}
            showElement={showElement}
            setShowElement={setShowElement}
        />
    );
}

export default OpacityErrorSuccessDiv;