import React from 'react';

function CollectionsIco() {
    return (
        <svg width="23px" height="23px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 4.5V18C10 19.08 9.56 20.07 8.86 20.79L8.82 20.83C8.73 20.92 8.63 21.01 8.54 21.08C8.24 21.34 7.9 21.54 7.55 21.68C7.44 21.73 7.33 21.77 7.22 21.81C6.83 21.94 6.41 22 6 22C5.73 22 5.46 21.97 5.2 21.92C5.07 21.89 4.94 21.86 4.81 21.82C4.65 21.77 4.5 21.72 4.35 21.65C4.35 21.64 4.35 21.64 4.34 21.65C4.06 21.51 3.79 21.35 3.54 21.16L3.53 21.15C3.4 21.05 3.28 20.95 3.17 20.83C3.06 20.71 2.95 20.59 2.84 20.46C2.65 20.21 2.49 19.94 2.35 19.66C2.36 19.65 2.36 19.65 2.35 19.65C2.35 19.65 2.35 19.64 2.34 19.63C2.28 19.49 2.23 19.34 2.18 19.19C2.14199 19.0612 2.10863 18.9312 2.08 18.8C2.03 18.54 2 18.27 2 18V4.5C2 3 3 2 4.5 2H7.5C9 2 10 3 10 4.5Z"
                stroke="#3A325B" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M22 16.5V19.5C22 21 21 22 19.5 22H5.99999C6.40999 22 6.82999 21.94 7.21999 21.81C7.32999 21.77 7.43999 21.73 7.54999 21.68C7.89999 21.54 8.23999 21.34 8.53999 21.08C8.62999 21.01 8.72999 20.92 8.81999 20.83L8.85999 20.79L15.66 14H19.5C21 14 22 15 22 16.5ZM4.80999 21.82C4.20999 21.64 3.63999 21.31 3.16999 20.83C2.68999 20.36 2.35999 19.79 2.17999 19.19C2.37412 19.8078 2.71463 20.3695 3.17254 20.8275C3.63044 21.2854 4.1922 21.6259 4.80999 21.82Z"
                stroke="#3A325B" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M18.37 11.29L15.66 14L8.86 20.79C9.56 20.07 10 19.08 10 18V8.34L12.71 5.63C13.77 4.57 15.19 4.57 16.25 5.63L18.37 7.75C19.43 8.81 19.43 10.23 18.37 11.29ZM6 19C6.26522 19 6.51957 18.8946 6.70711 18.7071C6.89464 18.5196 7 18.2652 7 18C7 17.7348 6.89464 17.4804 6.70711 17.2929C6.51957 17.1054 6.26522 17 6 17C5.73478 17 5.48043 17.1054 5.29289 17.2929C5.10536 17.4804 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19Z"
                stroke="#3A325B" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default CollectionsIco;