import React from 'react';

function Unfollowing({color}) {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.9792 28.4375H21.1458M17.4854 31.8062C14.8312 31.8062 12.1917 31.1354 10.1792 29.7937C6.65 27.4312 6.65 23.5812 10.1792 21.2333C14.1896 18.55 20.7667 18.55 24.7771 21.2333M17.7333 15.852C17.5875 15.8375 17.4125 15.8375 17.2521 15.852C15.5782 15.7952 13.9922 15.0891 12.8299 13.8832C11.6676 12.6774 11.0202 11.0665 11.025 9.39163C11.0227 8.5419 11.1881 7.70007 11.5118 6.9144C11.8355 6.12873 12.311 5.41466 12.9112 4.81313C13.5114 4.21161 14.2243 3.73445 15.0093 3.409C15.7942 3.08356 16.6357 2.91624 17.4854 2.91663C21.0583 2.91663 23.9604 5.81871 23.9604 9.39163C23.9604 12.8916 21.1896 15.7354 17.7333 15.852Z"
                  stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Unfollowing;