import React from 'react';

function Settings({color, width, height}) {
    return (
        <svg width={width ?width :"23px"} height={height ?height :"23px"} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.5 23.125C19.7266 23.125 20.903 22.6377 21.7704 21.7704C22.6377 20.903 23.125 19.7266 23.125 18.5C23.125 17.2734 22.6377 16.097 21.7704 15.2296C20.903 14.3623 19.7266 13.875 18.5 13.875C17.2734 13.875 16.097 14.3623 15.2296 15.2296C14.3623 16.097 13.875 17.2734 13.875 18.5C13.875 19.7266 14.3623 20.903 15.2296 21.7704C16.097 22.6377 17.2734 23.125 18.5 23.125Z"
                stroke={color ?color :"#3A325B"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M3.08331 19.8567V17.1433C3.08331 15.54 4.39373 14.2142 6.01248 14.2142C8.8029 14.2142 9.94373 12.2408 8.54081 9.82042C7.73915 8.43292 8.21706 6.62917 9.61998 5.8275L12.2871 4.30125C13.505 3.57667 15.0775 4.00834 15.8021 5.22625L15.9716 5.51917C17.3591 7.93959 19.6408 7.93959 21.0437 5.51917L21.2133 5.22625C21.9379 4.00834 23.5104 3.57667 24.7283 4.30125L27.3954 5.8275C28.7983 6.62917 29.2762 8.43292 28.4746 9.82042C27.0716 12.2408 28.2125 14.2142 31.0029 14.2142C32.6062 14.2142 33.9321 15.5246 33.9321 17.1433V19.8567C33.9321 21.46 32.6216 22.7858 31.0029 22.7858C28.2125 22.7858 27.0716 24.7592 28.4746 27.1796C29.2762 28.5825 28.7983 30.3708 27.3954 31.1725L24.7283 32.6988C23.5104 33.4233 21.9379 32.9917 21.2133 31.7738L21.0437 31.4808C19.6562 29.0604 17.3746 29.0604 15.9716 31.4808L15.8021 31.7738C15.0775 32.9917 13.505 33.4233 12.2871 32.6988L9.61998 31.1725C8.94797 30.7856 8.45692 30.1478 8.2546 29.3992C8.05227 28.6506 8.15521 27.8524 8.54081 27.1796C9.94373 24.7592 8.8029 22.7858 6.01248 22.7858C4.39373 22.7858 3.08331 21.46 3.08331 19.8567Z"
                stroke={color ?color :"#3A325B"} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Settings;